<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3Box max-w-1400">
        <img :src="importedImg3" alt="" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4Box max-w-1400">
        <img :src="importedImg4" alt="" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5Box max-w-1400">
        <!-- <img :src="importedImg5" alt="" class="imgItem5" /> -->
      </div>
    </div>
    <div class="imgBox6 min-w-1400">
      <div class="img6Box max-w-1400">
        <!-- <img :src="importedImg6" alt="" class="imgItem6" /> -->
      </div>
    </div>
    <div class="imgBox7 min-w-1400">
      <div class="img7Box max-w-1400">
        <!-- <img :src="importedImg7" alt="" class="imgItem7" /> -->
      </div>
    </div>
    <div class="imgBox8 min-w-1400">
      <div class="img8Box max-w-1400">
        <!-- <img :src="importedImg7" alt="" class="imgItem8" /> -->
      </div>
    </div>
    <div class="imgBox9 min-w-1400">
      <div class="img9Box max-w-1400">
        <!-- <img :src="importedImg9" alt="" class="imgItem9" /> -->
      </div>
    </div>
    <div class="imgBox10 min-w-1400">
      <div class="img10Box max-w-1400">
        <!-- <img :src="importedImg10" alt="" class="imgItem10" /> -->
      </div>
    </div>
    <div class="imgBox11 min-w-1400">
      <div class="img11Box max-w-1400">
        <!-- <img :src="importedImg11" alt="" class="imgItem11" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/phayaopicocompany/components/img/3_1.png";
import importedImg2 from "@/page/phayaopicocompany/components/img/3_2.png";
import importedImg3 from "@/page/phayaopicocompany/components/img/3_3.png";
import importedImg4 from "@/page/phayaopicocompany/components/img/3_4.png";
// import importedImg5 from "@/page/phayaopicocompany/components/img/3_5.png";
// import importedImg6 from "@/page/phayaopicocompany/components/img/3_6.png";
// import importedImg7 from "@/page/phayaopicocompany/components/img/3_7.png";
// import importedImg8 from "@/page/phayaopicocompany/components/img/3_8.png";
// import importedImg9 from "@/page/phayaopicocompany/components/img/3_9.png";
// import importedImg10 from "@/page/phayaopicocompany/components/img/3_10.png";
// import importedImg11 from "@/page/phayaopicocompany/components/img/3_11.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      // importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,
      // importedImg8: importedImg8,
      // importedImg9: importedImg9,
      // importedImg10: importedImg10,
      // importedImg11: importedImg11,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
img {
  display: block;
}
.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7,
.imgBox8,
.imgBox9,
.imgBox10,
.imgBox11 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box,
.img8Box,
.img9Box,
.img10Box,
.img11Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11 {
  width: 100%;
  margin: 0;
}
.img3Box{
  padding: 0 220px;
}
.img2Box {
  margin: 100px 0px 130px;
}
.img3Box {
  margin-bottom: 130px;
}
</style>
